import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { MicrocyclesService } from '../../services/microcycles.service';
import { ExercisesService } from '../../services/exercises.service';
import { SquadService } from '../../services/squad.service';
import { GameService } from '../../services/game.service';
import { DomSanitizer } from '@angular/platform-browser'
import { LoadingService } from '../../services/loading.service';

import * as moment from 'moment';

@Component({
  selector: 'app-microcycles',
  templateUrl: './microcycles.component.html',
  styleUrls: ['./microcycles.component.scss']
})
export class MicrocyclesComponent implements OnInit {

  constructor(public dataService: DataService,
              public microService: MicrocyclesService,
              public exercisesService: ExercisesService,
              public gameService: GameService,
              public squadService: SquadService,
              public sanitizer:DomSanitizer,
              public loadingService: LoadingService) { }
  loading = true;
  loading_graphs = true;
  micros;
  selected_season = null;
  categories;
  @Input() season: any;
  
  main_controller = {
    micro: null,
    ddl: false,
    sessions: null
  };

  compare_controller = {
    visible: false,
    possibilities: null,
    label: "",
    ddl:false,
    micros : []
  };
  
  graph_controller = [];
  labels = ["A.Rec", "Stren.", "Resis.", "Spee", "Rest", "Gene.", "Match"];
  graphs = [];  
  pies = [];

  players = {};

  ngOnInit() {
    // this.selected_season = this.dataService.selected_project.seasons[this.season];
    // this.getSquad();
    // this.getCategories();
  }

  getSquad(){
    let project = this.dataService.getSelectedProject();
    let targetSeason = null;
    for (let it = 0; it < project.seasons.length; it++) {
      const season_candidate = project.seasons[it];
      if(season_candidate.id == this.selected_season.id){
        targetSeason = season_candidate;
        break;
      }
    }
    if(targetSeason != null){
      this.squadService.getSquad(project.project_team_id, targetSeason.project_season_id).subscribe(data=>{
        let squad = [];
        if(data.hasOwnProperty('data')){
          if(data['data'].hasOwnProperty('squad')){
            squad = data['data']['squad'];
          } else {
            squad = data['data'];
          }
          this.players['squad'] = squad;
        }
        this.getCategories();
      });
    }
  }
  
  ngOnChanges(changes) {
    this.loading = true; 
    this.loadingService.loading = true;    
    this.selected_season = this.dataService.selected_project.seasons[this.season];
    this.getSquad();
    this.getCategories();
  }

  getCategories(){
    this.exercisesService.getMainCategories().subscribe(data=> {
      this.categories = data['main'];
      this.getMicros();
    });
  }

  getMicros(){
    var fd = new FormData();
    fd.append('id', this.dataService.getSelectedProject().id);
    fd.append('season_id', this.selected_season.id);
    this.microService.getMicrosAllInfo(fd).subscribe(data=>{
      this.micros = data;
      this.micros.forEach((micro, it) => {
        micro.name = "M"+(it+1);
      });
      this.processMicros();
    });
  }

  checkIfComlete(micro){
    for (let it = 0; it < micro.trainings.length; it++) {
      const train = micro.trainings[it];
      if(train.template_selected == null){
        return false;
      }
    }
    for (let it = 0; it < micro.games.length; it++) {
      const game = micro.games[it];
      if(game['original-gameVars'] == null){
        return false;
      }
    }
    return true;
  }

  processMicros(){
    let targetSeason = null;
    for (let index = 0; index < this.dataService.getSelectedProject().seasons.length; index++) {
      const season = this.dataService.getSelectedProject().seasons[index];
      if(season.id == this.selected_season.id){
        targetSeason = season;
        break;
      }
    }
    let games = targetSeason.games;
    this.micros.forEach((micro, it) => {
      let micro_games = [];
      var startDateMicro = moment(micro.date_begin);
      var endDateMicro = moment(micro.date_end);
      micro.schedule = {};
      var currDate = moment(micro.date_begin).startOf('day');
      var lastDate = moment(micro.date_end).startOf('day');
      micro.schedule[currDate.format("YYYY-MM-DD")] = ({weekDay:this.getWeekDay(currDate), date:currDate.format("YYYY-MM-DD")}); 
      while(currDate.add(1, 'days').diff(lastDate) <= 0) {
        micro.schedule[currDate.format("YYYY-MM-DD")] = ({weekDay:this.getWeekDay(currDate), date:currDate.format("YYYY-MM-DD")}); 
      }

      micro['games'] = micro_games;

      games.forEach((game) => {
        if(game.status_name !== 'Postponed'){
          let compareDate = moment(game.time.date);
          if(compareDate.isBetween(startDateMicro, endDateMicro, undefined, '[]')){
            let gameVars = this.checkGameVars(game.id, micro.vars_game);
            game['gameVars'] = this.getMyTeamGameVars(gameVars, game); 
            game['original-gameVars'] = gameVars; 
            micro.schedule[compareDate.format("YYYY-MM-DD")].type="game";
            micro.schedule[compareDate.format("YYYY-MM-DD")].content = game;
            micro_games.push(game);
          }
          if(compareDate.isAfter(endDateMicro)){ 
            micro['games'] = micro_games;
            return false;
          }
        }
      });

      micro.skeleton = {
        matches: micro.games.length,
        rest: micro.rest_days.length,
      };
      micro.rest_days.forEach(rest =>{
        micro.schedule[rest['date']].type = "rest";
        micro.schedule[rest['date']].content = rest;
      });
      micro.trainings.forEach(training => {

        if(micro.skeleton['cat_'+training.category] === undefined){
          micro.skeleton['cat_'+training.category] = 1;
        }
        else {
          micro.skeleton['cat_'+training.category] = micro.skeleton['cat_'+training.category] + 1;
        } 

        micro.schedule[training['data']].type = "session";
        training.totals = this.getTrainingTotals(training);
        micro.schedule[training['data']].content = training;
      });

      if(it == (this.micros.length - 1)){
        setTimeout(() => {
          this.loading = false;
          this.loadingService.loading = false;
        }, 1000);
      }
    });
  }

  checkGameVars(game_id, games){
    let gameVars = null;
    for (let it = 0; it < games.length; it++) {
      const game = games[it];
      if(game.game_id == game_id){
        gameVars = game;
        break;
      }
    }
    return gameVars;
  }

  getMyTeamGameVars(gameVars, game){
    let vars = {
      total_distance: 0,
      nr_sprints: 0,
      mts_sprint: 0,
      acc: 0,
      decc: 0,
      hi_runs: 0,
      time: gameVars != null ? gameVars.time : 0,
      rpe: gameVars != null ? gameVars.rpe : 0
    };
    let team_vars = null;
    let teamId = this.dataService.selected_project.project_team_id;
    let prefix = "";
    if(gameVars != null){
      if(teamId == gameVars.away_team_id){
        team_vars = gameVars.away_team_vars;
        prefix = "away_";
      } else if(teamId == gameVars.home_team_id){
        team_vars = gameVars.home_team_vars;
        prefix = "home_";
      }
      team_vars = JSON.parse(team_vars);
      vars['total_distance'] += (Number(team_vars[prefix+'team_first_distance']) + Number(team_vars[prefix+'team_second_distance']));
      vars['nr_sprints'] += (Number(team_vars[prefix+'team_first_sprints']) + Number(team_vars[prefix+'team_second_sprints']));
      vars['acc'] += (Number(team_vars[prefix+'team_first_acc']) + Number(team_vars[prefix+'team_second_acc']));
      vars['decc'] += (Number(team_vars[prefix+'team_first_decc']) + Number(team_vars[prefix+'team_second_decc']));
      vars['mts_sprint'] += (Number(team_vars[prefix+'team_first_mts_of_sprint']) + Number(team_vars[prefix+'team_second_mts_of_sprint']));
      vars['hi_runs'] += (Number(team_vars[prefix+'team_first_hi_runs']) + Number(team_vars[prefix+'team_second_hi_runs']));
    }
    return vars;
  }

  getTrainingTotals(session){
    let totals = {
      total_distance: 0,
      nr_sprints: 0,
      mts_sprint: 0,
      acc: 0,
      decc: 0,
      hi_runs: 0,
      time: 0
    };
    if(session['template_selected'] != null){
      let template = JSON.parse(session['template_selected']);
      template.circuits.forEach(circuit => {
        circuit.exercicios.forEach(exercicio => {
          let temp_total_distance = Number(exercicio.gps_vars.total_distance); 
          let temp_nr_sprints = Number(exercicio.gps_vars.nr_sprints);
          let temp_mts_nr_sprints = Number(exercicio.gps_vars.mts_sprint);  
          let temp_acc = Number(exercicio.gps_vars.acc);
          let temp_decc = Number(exercicio.gps_vars.decc);
          let temp_hi_runs = Number(exercicio.gps_vars.hi_runs);
          let temp_time = Number(exercicio.time);    
          totals.total_distance += temp_total_distance*exercicio.time*exercicio.reps;
          totals.nr_sprints += Math.round(temp_nr_sprints*exercicio.time*exercicio.reps*10)/10;
          totals.mts_sprint += Math.round(temp_mts_nr_sprints*exercicio.time*exercicio.reps*10)/10;
          totals.acc += Math.round(temp_acc*exercicio.time*exercicio.reps*10)/10;
          totals.decc += temp_decc;
          totals.hi_runs += Math.round(temp_hi_runs*exercicio.time*exercicio.reps);
          totals.time += temp_time;
        });
      });
    }
    return totals;
  }

  getWeekDay(date){
    let day = date.isoWeekday();
    let label = "";
    if(day == 1){
      label = "Mon";
    }
    else if(day == 2){
      label = "Tue";
    }
    else if(day == 3){
      label = "Wed";
    }
    else if(day == 4){
      label = "Thu";
    }
    else if(day == 5){
      label = "Fri";
    }
    else if(day == 6){
      label = "Sat";
    }
    else if(day == 7){
      label = "Sun";
    }
    return label;
  }

  chooseMainMicro(micro){
    this.compare_controller = {
      visible: false,
      possibilities: null,
      label: "",
      ddl:false,
      micros: []
    };
    this.main_controller.micro = micro;
    var begin_date = moment(micro.date_begin).startOf('day');
    var end_date = moment(micro.date_end).startOf('day');
    var days_diff = end_date.diff(begin_date);
    var nr_days = moment.duration(days_diff)['_data']['days'];
    let temp_micros = [];    
    this.micros.forEach(temp_micro => {
      let temp_beg = moment(temp_micro.date_begin).startOf('day');
      let temp_end = moment(temp_micro.date_end).startOf('day');
      let days_diff = temp_end.diff(temp_beg);
      let temp_nr_days = moment.duration(days_diff)['_data']['days'];
      if(temp_nr_days == nr_days 
         && micro.id != temp_micro.id
         && JSON.stringify(this.main_controller.micro.skeleton) == JSON.stringify(temp_micro.skeleton)){
        temp_micro.checked = false;
        temp_micros.push(temp_micro);
      }
    });
    if(temp_micros.length > 0){
      this.compare_controller.visible = true;
      this.compare_controller.possibilities = temp_micros;
    }
    this.processDataForGraphs();
  }

  getCatColor(session){
    let catInfo = this.categories.find(item => {
      return item.id == session.category;
    });
    return catInfo.color;
  }

  getCatName(session){
    let catInfo = this.categories.find(item => {
      return item.id == session.category;
    });
    return catInfo.short_name;
  }

  processDataForGraphs(){
    this.graphs = [];
    this.graph_controller = [];
    this.graph_controller[0] = {};
    var labels = [];   
    var colors = [];
    var values_distance = [];
    var values_sprints = [];
    var values_mts_sprint = [];
    var values_acc = [];
    var values_decc = [];
    var values_hi_runs = [];
    var values_times = [];
    for (let key in this.main_controller.micro.schedule) {
      let label = [this.main_controller.micro.schedule[key].weekDay];
      if(this.main_controller.micro.schedule[key].hasOwnProperty('type')){
        if(this.main_controller.micro.schedule[key]['type'] == "session"){
          let color = this.getCatColor(this.main_controller.micro.schedule[key]['content']);
          label.push(this.getCatName(this.main_controller.micro.schedule[key]['content'])); 
          colors.push(color);
          values_distance.push(this.main_controller.micro.schedule[key]['content'].totals.total_distance);
          values_sprints.push(this.main_controller.micro.schedule[key]['content'].totals.nr_sprints);
          values_mts_sprint.push(this.main_controller.micro.schedule[key]['content'].totals.mts_sprint);
          values_acc.push(this.main_controller.micro.schedule[key]['content'].totals.acc);
          values_decc.push(this.main_controller.micro.schedule[key]['content'].totals.decc);
          values_hi_runs.push(this.main_controller.micro.schedule[key]['content'].totals.hi_runs);
          values_times.push(this.main_controller.micro.schedule[key]['content'].session_time * this.main_controller.micro.schedule[key]['content'].session_rpe);
        } else if(this.main_controller.micro.schedule[key]['type'] == "rest"){
          colors.push("#0A784C");
          values_distance.push(0);
          values_sprints.push(0);
          values_mts_sprint.push(0);
          values_acc.push(0);
          values_decc.push(0);
          values_hi_runs.push(0);
          values_times.push(0);
          label.push("Rest");
        } else if(this.main_controller.micro.schedule[key]['type'] == "game"){
          colors.push("#FFA642");
          values_distance.push(this.main_controller.micro.schedule[key]['content'].gameVars.total_distance);
          values_sprints.push(this.main_controller.micro.schedule[key]['content'].gameVars.nr_sprints);
          values_mts_sprint.push(this.main_controller.micro.schedule[key]['content'].gameVars.mts_sprint);
          values_acc.push(this.main_controller.micro.schedule[key]['content'].gameVars.acc);
          values_decc.push(this.main_controller.micro.schedule[key]['content'].gameVars.decc);
          values_hi_runs.push(this.main_controller.micro.schedule[key]['content'].gameVars.hi_runs);
          values_times.push(Number(this.main_controller.micro.schedule[key]['content'].gameVars.time) * Number(this.main_controller.micro.schedule[key]['content'].gameVars.rpe));
          label.push("Game");
        } 
      } else {
        colors.push("rgb(255,255,255)");
      }
      labels.push(label);
    }
    // Training Load
    this.graphs.push({
      title:'Training Load',
      labels: labels,
      data: [
        {
          data: values_times,
          backgroundColor: colors,

          hoverBackgroundColor: colors 
        }
      ],
      type: 'bar',
      legend: false,
      options:{
        scaleShowVerticalLines: false,
        responsive: true,
        maintainAspectRatio:false, 
        scales: {
          xAxes: [
            {
              barThickness: 10,
              ticks: {
                fontColor: 'rgba(255, 255, 255, 0.60)',
                maxRotation: 0,
                minRotation: 0,
              }
            }
          ],
          yAxes:[{
            ticks: {
              fontColor: 'white',
              min: 0,
              beginAtZero: true,
              // callback: function(label, index, labels) {
              //   if(label == 0){
              //     return 0;
              //   }
              //   return label/1000+'k';
              // }
            }
          }]
        }
      },
    });
    //HI Runs
    this.graphs.push({
      title:'HI Runs',
      labels: labels,
      data: [
        {
          data: values_hi_runs,
          backgroundColor: colors,
          hoverBackgroundColor: colors
        }
      ],
      type: 'bar',
      legend: false,
      options:{
        scaleShowVerticalLines: false,
        responsive: true,
        maintainAspectRatio:false, 
        scales: {
          xAxes: [
            {
              barThickness: 10,
              ticks:{
                fontColor: 'rgba(255, 255, 255, 0.60)',
                maxRotation: 0,
                minRotation: 0,
              }
            }
          ],
          yAxes:[{
            ticks: {
              fontColor: 'white',
              min: 0,
              beginAtZero: true,
              // callback: function(label, index, labels) {
              //   if(label == 0){
              //     return 0;
              //   }
              //   return label/1000+'k';
              // }
            }
          }]
        }
      }
    });
    // Nr of Sprints
    this.graphs.push({
      title:'Number of Sprints',
      labels: labels,
      data: [
        {
          data: values_sprints,
          backgroundColor: colors,
          hoverBackgroundColor: colors
        }
      ],
      type: 'bar',
      legend: false,
      options:{
        scaleShowVerticalLines: false,
        responsive: true,
        maintainAspectRatio:false, 
        scales: {
          xAxes: [
            {
              barThickness: 10,
              ticks:{
                fontColor: 'rgba(255, 255, 255, 0.60)',
                maxRotation: 0,
                minRotation: 0,
              }
            }
          ],
          yAxes:[{
            ticks: {
              fontColor: 'white',
              min: 0,
              beginAtZero: true,
              // callback: function(label, index, labels) {
              //   if(label == 0){
              //     return 0;
              //   }
              //   return label/1000+'k';
              // }
            }
          }]
        }
      }
    });
    // Total Distance
    this.graphs.push({
      title: 'Total Distance',
      labels: labels,
      data: [
        {
          data: values_distance, 
          backgroundColor: colors,
          hoverBackgroundColor: colors
        }
      ],
      type: 'bar',
      legend: false,
      options:{
        scaleShowVerticalLines: false,
        responsive: true,
        maintainAspectRatio:false, 
        scales: {
          xAxes: [
            {
              barThickness: 10,
              ticks:{
                fontColor: 'rgba(255, 255, 255, 0.60)',
                maxRotation: 0,
                minRotation: 0,
              },
              callback: function(label, index, labels) {
                if(label == 0){
                  return 0;
                }
                return label+'merda';
              } 
            }
          ],
          yAxes:[{
            ticks: {
              fontColor: 'white',
              min: 0,
              beginAtZero: true,
              // callback: function(label, index, labels) {
              //   if(label == 0){
              //     return 0;
              //   }
              //   return label/1000+'k';
              // }
            }
          }]
        }
      }
    });
    // Meters of sprint
    this.graphs.push({
      title:'Meters of Sprint',
      labels: labels,
      data: [
        {
          data: values_mts_sprint,
          backgroundColor: colors,
          hoverBackgroundColor: colors
        }
      ],
      type: 'bar',
      legend: false,
      options:{
        scaleShowVerticalLines: false,
        responsive: true,
        maintainAspectRatio:false, 
        scales: {
          xAxes: [
            {
              barThickness: 10,
              ticks:{
                fontColor: 'rgba(255, 255, 255, 0.60)',
                maxRotation: 0,
                minRotation: 0,
              }
            }
          ],
          yAxes:[{
            ticks: {
              fontColor: 'white',
              min: 0,
              beginAtZero: true,
              // callback: function(label, index, labels) {
              //   if(label == 0){
              //     return 0;
              //   }
              //   return label/1000+'k';
              // }
            }
          }]
        }
      },
    });
    // ACC 
    this.graphs.push({
      title:'MechW',
      labels: labels,
      data: [
        {
          data: values_acc,
          backgroundColor: colors,
          hoverBackgroundColor: colors
        }
      ],
      type: 'bar',
      legend: false,
      options:{
        scaleShowVerticalLines: false,
        responsive: true,
        maintainAspectRatio:false, 
        scales: {
          xAxes: [
            {
              barThickness: 10,
              ticks:{
                fontColor: 'rgba(255, 255, 255, 0.60)',
                maxRotation: 0,
                minRotation: 0,
              }
            }
          ],
          yAxes:[{
            ticks: {
              fontColor: 'white',
              min: 0,
              beginAtZero: true
            }
          }]
        }
      }
    });
    // DECC 
    this.graphs.push({
      title:'DECC',
      labels: labels,
      data: [
        {
          data: values_decc,
          backgroundColor: colors,
          hoverBackgroundColor: colors
        }
      ],
      type: 'bar',
      legend: false,
      options:{
        scaleShowVerticalLines: false,
        responsive: true,
        maintainAspectRatio:false, 
        scales: {
          xAxes: [
            {
              barThickness: 10,
              ticks:{
                fontColor: 'rgba(255, 255, 255, 0.60)',
                maxRotation: 0,
                minRotation: 0,
              }
            }
          ],
          yAxes:[{
            ticks: {
              fontColor: 'white',
              min: 0,
              beginAtZero: true
            }
          }]
        }
      }
    });
    this.setRoundMainGraph();
    this.loading_graphs = false;
    this.loadingService.loading = false;
  }

  setRoundMainGraph(){
    let categories = [];
    this.pies = [];
    this.main_controller.micro.trainings.forEach(session => {
      if(session.template_selected != null){
        let template = JSON.parse(session.template_selected);
        template.circuits.forEach(circuit => {
          circuit.exercicios.forEach(exercicio => {
            categories.push(exercicio.sub_category_name);
          });
        });
      }
    });
    var labels = [];
    var values = [];
    var obj = {
      'Analytic physical work': 0,
      'Finishing': 0,
      'Match':0,
      'Possession':0,
      'Small space / Duels': 0,
      'Tactital work': 0,
      'Warm up' : 0,
      'Pass Circuit' :0
    };
    categories.forEach(category => {
      obj[category] = obj[category]+1;
    });
    for (var [key, value] of Object.entries(obj)) {
      labels.push(key);
      values.push(value / categories.length * 100);
    }
    this.pies.push({
      labels: labels,
      data: [{data: values}],
      type: 'pie',
      legend: false,
      main: true,
      options:{
        scaleShowVerticalLines: false,
        responsive: true,
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              return data.datasets[0].data[tooltipItem.index].toFixed(2) + "%";
            }
          }
        }      
      },
      title: "Session's Content",
      color: [{
        backgroundColor: ['#369afd', '#fd5c5c', '#02f3a7', '#ff9346', '#2afffe', '#f0a2ff', '#d8d8d8', '#fff085'],
        borderColor: ['transparent','transparent','transparent','transparent','transparent','transparent','transparent','transparent']
     }]
    });
    this.playersMainStats();
  }

  playersMainStats(){
    this.players['squad'].forEach(player => {
      player['trainTime'] = 0;
    });
    let totalTime = 0;
    this.main_controller.micro.trainings.forEach(session => {
      if(session.players != null){
        let players_groups = JSON.parse(session.players);
        this.players['squad'].forEach(squad_player => {
          let found = false;
          for (let it = 0; it < players_groups.training_players.length; it++) {
            const session_player = players_groups.training_players[it];
            if(session_player.player.id == squad_player.player.id){
              squad_player['trainTime'] += Number(session.session_time);
              found = true;
              break;
            }
          }
          if(!found){
            for (let it = 0; it < players_groups.active_recovery.length; it++) {
              const session_player = players_groups.active_recovery[it];
              if(session_player.player.id == squad_player.player.id){
                const str = session_player.duration;
                const m = moment(str, "H:mm");
                var minutes = (m.hour()*60) + m.minute();
                squad_player['trainTime'] += Number(minutes);
                found = true;
                break;
              }
            }
            if(!found){
              for (let it = 0; it < players_groups.partial_players.length; it++) {
                const session_player = players_groups.partial_players[it];
                if(session_player.player.id == squad_player.player.id){
                  const str = session_player.duration;
                  const m = moment(str, "HH:mm");
                  var minutes = (m.hour()*60) + m.minute();
                  squad_player['trainTime'] += Number(minutes);
                  found = true;
                  break;
                }
              } 
            }
          }
        });
        totalTime+= Number(session.session_time);
      }
    });
    this.players['squad'].forEach(player => {
      player['totalTime'] = totalTime;
    });
    this.players['squad']['processed_players'] = {
      A1Goalkeepers: [],
      A2Defensors: [],
      A3Middlefielders: [],
      A4Attackers: []
    };
    this.players['squad'].forEach( player => {
      if(player.position == "G"){
        this.players['squad']['processed_players']['A1Goalkeepers'].push(player);
      } 
      else if(player.position == "D"){
        this.players['squad']['processed_players']['A2Defensors'].push(player);
      }
      else if(player.position == "M"){
        this.players['squad']['processed_players']['A3Middlefielders'].push(player);
      }
      else if(player.position == "F"){
        this.players['squad']['processed_players']['A4Attackers'].push(player);
      }
    });
  }

  toggle_compare_micro(micro){
    micro.checked = !micro.checked;
    this.compare_controller.label = "";
    this.compare_controller.possibilities.forEach(pos => {
      if(pos.checked){
        this.compare_controller.label+=pos.name+",";   
      }
    });
    this.compare_controller.label = this.compare_controller.label.slice(0, -1);
    if(micro.checked){
         this.compare_controller.micros.push(micro);  
    } else {
      for (let it = this.compare_controller.micros.length-1; it >= 0; it--) {
        const micro_temp = this.compare_controller.micros[it];
        if(micro.id == micro_temp.id){
          this.compare_controller.micros.splice(it, 1);
        }
      }
    }
    this.processCompare();
  }

  processCompare(){
    if(this.compare_controller.label == ""){
      this.processDataForGraphs();
    } else {
      if(this.graphs[0].data.length>1){
        this.graphs[0].data.splice(-1,1);
        this.graphs[1].data.splice(-1,1);
        this.graphs[2].data.splice(-1,1);
        this.graphs[3].data.splice(-1,1);
        this.graphs[4].data.splice(-1,1);
        this.graphs[5].data.splice(-1,1);
      } 
      var comparsions = {};
      this.compare_controller.micros.forEach((comparable,it) => {
        comparsions[it] = [];
      });
      let compare_micros = [];
      this.compare_controller.micros.forEach(micro => {
        compare_micros.push(Object.assign({}, micro.schedule));
      });
      for (let key in this.main_controller.micro.schedule) {
        compare_micros.forEach((compareMicro,it) => {
          for(let key2 in compareMicro){
            if(this.main_controller.micro.schedule[key].type == compareMicro[key2].type){
              if(this.main_controller.micro.schedule[key].type == "game"){
                comparsions[it].push(compareMicro[key2]);
                delete compareMicro[key2];
                break;
              }
              if(this.main_controller.micro.schedule[key].type == "rest"){
                comparsions[it].push(compareMicro[key2]);
                delete compareMicro[key2];
                break;
              }
              if(this.main_controller.micro.schedule[key].type == "session" 
                 && this.main_controller.micro.schedule[key].content.category == compareMicro[key2].content.category){
                  comparsions[it].push(compareMicro[key2]);
                  delete compareMicro[key2];
                  break; 
              }
            }
          }
        });
      }

      var values_distance = [];
      var values_sprints = [];
      var values_mts_sprint = [];
      var values_acc = [];
      var values_decc = [];
      var values_hi_runs = [];
      var values_times = [];
      
      let it = 0;
      for (const key in this.main_controller.micro.schedule) {
        let value_distance = 0;
        let value_sprint = 0;
        let value_mts_sprint = 0;
        let value_acc = 0;
        let value_decc = 0;
        let value_hi_runs = 0;
        let value_times = 0;
        for (const key in comparsions) {
          let comparsion = comparsions[key];
          let event = comparsion[it];
          if(event.type == "session"){
            value_distance += event.content.totals.total_distance;
            value_sprint += event.content.totals.nr_sprints;
            value_mts_sprint += event.content.totals.mts_sprint;
            value_acc += event.content.totals.acc;
            value_decc += event.content.totals.decc;
            value_hi_runs += event.content.totals.hi_runs;
            value_times += event.content.session_time * event.content.session_rpe;
          } else if(event.type == "rest"){
            value_distance += 0;
            value_sprint += 0;
            value_mts_sprint += 0;
            value_acc += 0;
            value_decc += 0;
            value_hi_runs += 0;
            value_times += 0;
          } else if(event.type == "game"){
            value_distance += event.content.gameVars.total_distance;
            value_sprint += event.content.gameVars.nr_sprints;
            value_mts_sprint += event.content.gameVars.mts_sprint;
            value_acc += event.content.gameVars.acc;
            value_decc += event.content.gameVars.decc;
            value_hi_runs += event.content.gameVars.hi_runs;
            value_times += (Number(event.content.gameVars.time) * Number(event.content.gameVars.rpe));
          }
        }
        values_distance.push((value_distance/ Object.keys(comparsions).length));
        values_sprints.push((value_sprint / Object.keys(comparsions).length));
        values_mts_sprint.push((value_mts_sprint / Object.keys(comparsions).length));
        values_acc.push((value_acc / Object.keys(comparsions).length));
        values_decc.push((value_decc / Object.keys(comparsions).length));
        values_hi_runs.push((value_hi_runs / Object.keys(comparsions).length));
        values_times.push((value_times / Object.keys(comparsions).length));
        it++;
      }
      
      var colors = this.graphs[0].data[0].backgroundColor;
      this.graphs[0].data.push(
        {
          data: values_times,
          // backgroundColor: colors,
          backgroundColor:'rgba(255,255,255,0.28)',
          hoverBackgroundColor: colors 
        }
      );
      this.graphs[1].data.push(
        {
          data: values_hi_runs,
          // backgroundColor: colors,
          backgroundColor:'rgba(255,255,255,0.28)',
          hoverBackgroundColor: colors 
        }
      );
      this.graphs[2].data.push(
        {
          data: values_sprints,
          // backgroundColor: colors,
          backgroundColor:'rgba(255,255,255,0.28)',
          hoverBackgroundColor: colors 
        }
      );
      this.graphs[3].data.push(
        {
          data:values_distance,
          // backgroundColor: colors,
          backgroundColor:'rgba(255,255,255,0.28)',
          hoverBackgroundColor: colors 
        }
      );
      this.graphs[4].data.push(
        {
          data:values_mts_sprint,
          // backgroundColor: colors,
          backgroundColor:'rgba(255,255,255,0.28)',
          hoverBackgroundColor: colors 
        }
      );
      this.graphs[5].data.push(
        {
          data:values_acc,
          // backgroundColor: colors,
          backgroundColor:'rgba(255,255,255,0.28)',
          hoverBackgroundColor: colors 
        }
      );
      this.graphs[6].data.push(
        {
          data:values_decc,
          // backgroundColor: colors,
          backgroundColor:'rgba(255,255,255,0.28)',
          hoverBackgroundColor: colors 
        }
      );
      this.setRoundPieCompare();
    }
  }

  setRoundPieCompare(){
    if(this.pies.length > 1){
      this.pies.splice(-1,1);
    }
    let categories = [];
    this.compare_controller.micros.forEach(micro => {
      micro.trainings.forEach( session => {
        if(session.template_selected != null){
          let template = JSON.parse(session.template_selected);
          template.circuits.forEach(circuit => {
            circuit.exercicios.forEach(exercicio => {
              categories.push(exercicio.sub_category_name);
            });
          });
        }
      });
    });
    var labels = [];
    var values = [];
    var obj = {
      'Analytic physical work': 0,
      'Finishing': 0,
      'Match':0,
      'Possession':0,
      'Small space / Duels': 0,
      'Tactital work': 0,
      'Warm up' : 0,
      'Pass Circuit' :0
    };
    categories.forEach(category => {
      obj[category] = obj[category]+1;
    });
    for (var [key, value] of Object.entries(obj)) {
      labels.push(key);
      var valor =  value / this.compare_controller.micros.length;
      values.push(valor / categories.length * 100);
    }
    this.pies.push({
      labels: labels,
      data: [{data: values}],
      type: 'pie',
      legend: false,
      main: true,
      options:{
        scaleShowVerticalLines: false,
        responsive: true,
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              return data.datasets[0].data[tooltipItem.index].toFixed(2) + "%";
            }
          }
        }      
      },
      title: "Session's Content",
      color: [{
        backgroundColor: ['#369afd', '#fd5c5c', '#02f3a7', '#ff9346', '#2afffe', '#f0a2ff', '#d8d8d8', '#fff085'],
        borderColor: ['transparent','transparent','transparent','transparent','transparent','transparent','transparent','transparent']
     }]
    });
  }

  getColor(trainTime, totalTime){
    if(trainTime >= totalTime ){
      return "#02f3a7";
    }
    if(trainTime >= (totalTime*.75)){
      return "#fff085";
    }
    return "red";
  }

  getComparsionLabel(iG, iL){
    let firstValue = this.graphs[iG].data[0]['data'][iL];
    let secondValue = this.graphs[iG].data[1]['data'][iL];
    if(firstValue != 0){
      let value = Math.abs((secondValue - firstValue) / firstValue * 100);
      let color = value < 5 ? '#02f3a7' : value >=5 && value < 10 ? '#FFED58' : 'red'; 
      return this.sanitizer.bypassSecurityTrustHtml("<span style='color:"+color+"'>" + (firstValue>secondValue ? "-" : "") +value.toFixed(0) + "%</span>");
    } 
    return "";
  }
}
